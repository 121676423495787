shopList<template>
    <div>
        <GlobalForm class="global-form" :needBtnLoading="true" :init-data="initData" :form-item-list="formItemList"
            :inline="true" :labelWidth="100" round @handleConfirm="handleConfirm" confirmBtnName="查询">
            <el-form-item class="el_from" label="订单编号" slot="orderNo" style="margin:0">
                <el-input v-model="orderNo" placeholder="请输入订单号"></el-input>
            </el-form-item>
            <el-form-item class="el_from" label="门店商户" slot="shop" style="margin:0">
                <el-select v-model="shop" placeholder="请选择" clearable filterable>
                    <el-option v-for="item in shopList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="活动名称" slot="activeName" style="margin:0;">
                <el-select v-model="activeName" :placeholder="!shop ? '请先选择门店商户' : '请选择活动名称'" clearable filterable
                    @change="selecthandleChange" :disabled="!shop">
                    <el-option v-for="item in activeList" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="门店名称" slot="shopName" style="margin:0">
                <el-select v-model="shopName" :placeholder="!shop ? '请先选择门店商户' : '请选择活动名称'" clearable filterable
                    :disabled="!shop">
                    <el-option v-for="item in shopNameList" :key="item.key" :label="item.value"
                        :value="item.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="领取人" slot="lottery" style="margin:0">
                <!-- <el-select v-model="lottery" placeholder="请选择" clearable filterable>
                    <el-option v-for="item in lotteryList" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                </el-select> -->
                <el-input v-model="lottery" placeholder="请输入领取人手机号查询"></el-input>
            </el-form-item>
            <!-- <el-form-item class="el_from" label="红包状态" slot="redbageStatus" style="margin:0" v-if="tabval == '01'">
                <el-select v-model="redbageStatus" placeholder="请选择" clearable filterable>
                    <el-option v-for="item in redbageStatusList" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item class="el_from" :label="tabval == '00' ? '发放时间' : '退回时间'" slot="dateTime" style="margin:0">
                <el-date-picker style="margin-left: 10px;" v-model="dateTime" :picker-options="pickerOptions"
                    size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    :clearable="false" @change="dateTimeChange" :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
        </GlobalForm>
    </div>
</template>
<script>
import moment from 'moment'
import _api from "@/utils/request";
import { _http } from '../../../utils/request/bill';
export default {
    props: {
        tabval: {
            type: String,
            default: "01"
        }
    },
    data() {
        return {
            iid: null,
            initData: {
                dateTime: [
                    moment()
                        .day(moment().day() - 2)
                        .format("YYYY-MM-DD 00:00:00"),
                    moment().format("YYYY-MM-DD 23:59:59"),
                ],
            },
            copyType: '',
            activeList: [],
            pickerMinDate: null,
            pickerMaxDate: null,
            day31: 30 * 24 * 3600 * 1000,
            formItemList: [
                { slotName: "orderNo" },
                { slotName: "shop" },
                { slotName: "shopName" },
                { slotName: "activeName" },
                { slotName: "lottery" },
                // { slotName: "redbageStatus" },
                { slotName: "dateTime" },
            ],
            shopList: [],//门店商户数组
            shop: "",//选择的某个门店
            shopNameList: [],//门店名称数组
            shopName: "",//某个门店名称
            lottery: "",//某个领取人
            lotteryList: [],//领取人数组
            activeName: "",//某个活动名称
            dateTime: [moment()
                .day(moment().day() - 2)
                .format("YYYY-MM-DD 00:00:00"),
            moment().format("YYYY-MM-DD 23:59:59"),],//时间
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                },
                disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        return (
                            time.getTime() > this.pickerMinDate + this.day31 ||
                            time.getTime() < this.pickerMinDate - this.day31
                        );
                    }
                    return false;
                },
            },
            redbageStatus: "",//选择的红包状态
            redbageStatusList: [{ name: "待领取", id: "01" }, { name: "已领取", id: "02" }, { name: "已退回", id: "03" }],//状态列表
            orderNo: "",
        };
    },
    created() {
        console.log(this.tabval, 999)
        this.shop = Number(this.$route.query.companyId) || ""
        this.activeName = Number(this.$route.query.redActivityId) || ""
        console.log(this.initData, 9999)
        this.handleConfirm(this.initData)
        _api.getKACompanyList().then((res) => {
            if (res.code === 1) {
                this.shopList = res.data
                this.shopList.map(item => {
                })
            }
        })

    },
    mounted() { },
    watch: {
        shop(val) {
            if (val) {
                _http.dispatchGetActiveList({ companyId: this.shop, }).then(res => {
                    if (res.code == 1) {
                        this.activeList = res.data
                    }
                }).catch(err => {
                    console.log(err)
                })
                _api.getStoreSelectList({ companyId: this.shop, }).then(res => {
                    if (res.code === 1) {
                        this.shopNameList = res.data
                    }
                })
            }
        }
    },
    methods: {
        handleConfirm(data, cd) {
            console.log(data, 8888)
            console.log(this.orderNo, 9999)
            let SeachParams = {}
            let startTime = "";
            let endTime = "";
            startTime = moment(this.dateTime[0]).format("x");
            endTime = moment(this.dateTime[1]).format("x");
            SeachParams = {
                startTime: startTime,
                endTime: endTime,
                pageNum: 1,
                pageSize: 10,
                redActivityId: this.activeName,
                companyId: this.shop,
                storeId: this.shopName,
                staffMobile: this.lottery,
                orderNo: this.orderNo
            };
            // if (this.tabval == "01") {
            //     SeachParams.redbageStatus = this.redbageStatus
            // }
            console.log(SeachParams, 99999)
            SeachParams.disuseLoding = () => {
                if (typeof cd === 'function') {
                    cd();
                }
            },
                this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
        },
        selecthandleChange(e) {
            this.iid = e;
        },
        // 时间区间查询
        dateTimeChange(e) {
            console.log(e);
            //this.dateTime=[moment()
            //.day(e[0])
            //.format("YYYY-MM-DD 00:00:00"),
            //moment()
            //.day(e[1])
            //.format("YYYY-MM-DD 00:00:00").format("YYYY-MM-DD 23:59:59")]
            // this.getWaitSendOStatistics(1)
        }
    },
};
</script>
<style lang="scss" scoped>
.global-form {
    /deep/.el-input__inner {
        width: 180px;
    }

}

/deep/ .el-form-item:nth-child(5) .el-form-item__label {
    width: 100px !important;
}

/deep/ .el-form-item:nth-child(1) .el-form-item__label {
    width: 80px !important;
}

/deep/ .el-form-item:nth-child(2) .el-form-item__label {
    width: 80px !important;
}

/deep/ .el-form-item:nth-child(3) .el-form-item__label {
    width: 80px !important;
}

/deep/ .el-form-item:nth-child(4) .el-form-item__label {
    width: 80px !important;
}
</style>